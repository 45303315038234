// See the URL strategy doc at:
// https://gist.github.com/AThilenius/cd89c8f7f99c74cf5535c031783e194e
const ALIAS_REGEX = /^([^\.-]*)\.(.*)$/;
const DOKKU_REGEX = /^(.*)-(prod|stage)\.(dokku|dokku-failover)\.(.*)$/;

export interface DecomposedDokkuUrl {
  app: string;
  env: 'prod' | 'stage';
  dokkuHost: 'dokku' | 'dokku-failover';
  hostname: string;
}

export function tryDecomposeHostname(
  hostname: string
): DecomposedDokkuUrl | null {
  // Note to self: regex match groups start with the full string, followed by
  // the groups.
  hostname = hostname.toLowerCase();

  // Try to match the alias regex first
  const aliasParts = hostname.match(ALIAS_REGEX);
  if (aliasParts && aliasParts.length === 3) {
    return {
      app: aliasParts[1],
      env: 'prod',
      dokkuHost: 'dokku',
      hostname: aliasParts[2],
    };
  }

  // Then try to match the dokku regex
  const dokkuParts = hostname.match(DOKKU_REGEX);
  if (dokkuParts && dokkuParts.length === 5) {
    return {
      app: dokkuParts[1],
      env: dokkuParts[2] as any,
      dokkuHost: dokkuParts[3] as any,
      hostname: dokkuParts[4],
    };
  }

  return null;
}

export function toHostname(decomposed: DecomposedDokkuUrl): string {
  return `${decomposed.app}-${decomposed.env}.${decomposed.dokkuHost}.${decomposed.hostname}`;
}

export function getAppUrl(
  currentHostname: string,
  targetAppName: string,
  localhostValue?: string,
  throwOnFailure: boolean = true
): string | null {
  if (process.env.NODE_ENV === 'development' && localhostValue) {
    return localhostValue;
  }

  const decomposed = tryDecomposeHostname(currentHostname);

  if (!decomposed) {
    if (throwOnFailure) {
      throw new Error(`Failed to decompose the hostname ${currentHostname}`);
    } else {
      console.warn('Failed to decompose the hostname', currentHostname);
    }
    return null;
  }

  decomposed.app = targetAppName;
  return toHostname(decomposed);
}
